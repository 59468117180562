import React, { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard";
import MessageForm from "./MessageForm";
import jobs from "../data/experience.json";
import projectsData from "../data/projects.json";

export default function HomePage() {
  const [mobileView, setMobileView] = useState(false);
  const handleResize = () => {
    setMobileView(window.innerWidth < 576);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setMobileView(window.innerWidth < 576);
  }, []);

  const aboutContent = (
    <>
      <div className="about-content col-sm-8 col-sm-pull-4 col-xs-12">
        <section>
          <h3 className="about-title">About Me</h3>
          <div>
            <p>
              I'm a software engineer with a bachelor's degree at the{" "}
              <a href="https://ischool.uw.edu/" target="_blank" rel="noopener noreferrer" className="text-underline">
                Information School
              </a>
              , University of Washington, specializing in software development and cybersecurity. I enjoy building web applications that create flexibility and prioritize data security while delivering efficient solutions. In my journey as a developer, I've built various web applications using Java,
              React.JS, TypeScript, and SQL. Beyond academic pursuits, I am passionate about staying up-to-date with the latest advancements in cloud computing. I was certified as{" "}
              <a href="https://www.credly.com/badges/ada36a0e-f027-4004-a039-1f313858d79a/linked_in_profile" target="_blank" rel="noopener noreferrer" className="text-underline">
                AWS Cloud Practitioner
              </a>{" "}
              in April 2023 by dedicating time outside classes to thorough preparation. My career goal is to build and develop high-quality applications by utilizing best-fit technologies to deliver sustainable solutions and reliable products that meet customer needs and bring business value.
            </p>
          </div>
        </section>
      </div>
    </>
  );

  const campusWorkTitle = (
    <div className="contact-info col-sm-4 col-md-3 col-md-offset-1 col-sm-push-8 col-xs-12">
      <h3 className="campus-title">Work Experience</h3>
    </div>
  );

  const campusWork = (
    <div className="about-content col-sm-8 col-sm-pull-4 col-xs-12">
      <section>
        <div>
          {jobs.map((job, idx) => (
            <div className="job-container" key={idx}>
              <h4>{job.title}</h4>
              <p className="org">
                {job.organization}, {job.dates}
              </p>
              <p>{job.description}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );

  const projects = (
    <div className="about-content col-sm-11 col-sm-pull-4 col-xs-12">
      <h3 className="project-title">Projects</h3>
      <section>
        <div className="row">
          {projectsData.map((project, index) => (
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" key={index}>
              <div>
                <div className="blur-img-project" style={{ backgroundImage: `url("${project.imagePath}")` }}>
                  <img src={project.imagePath} className="img-fluid" loading="lazy" alt={`${project.title}-screenshot`} />
                </div>
                <div className="project-content">
                  <h5>
                    {project.title}
                    {project.link && (
                      <a href={project.link} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-link"></i>
                      </a>
                    )}
                    {project.githubLink && (
                      <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github github-in-line"></i>
                      </a>
                    )}
                  </h5>
                  <h6>
                    <i>{project.subtitle}</i>
                  </h6>
                  <p className="tools">{project.technologies}</p>
                  <p className="project-description">{project.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );

  return (
    <main>
      <div className="container">
        <div className="row">
          {mobileView ? (
            <>
              {<ProfileCard />}
              {aboutContent}
              {campusWorkTitle}
              {campusWork}
              {projects}
            </>
          ) : (
            <>
              {aboutContent}
              {<ProfileCard />}
              {campusWorkTitle}
              {campusWork}
              {projects}
            </>
          )}
          <MessageForm mobileView={mobileView} />
        </div>
      </div>
    </main>
  );
}
