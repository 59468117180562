import React, { useEffect, useState } from "react";
import ProfileCard from "./ProfileCard";
import MessageForm from "./MessageForm";

export default function ContactPage() {
  const [mobileView, setMobileView] = useState(false)
  const handleResize = () => { setMobileView(window.innerWidth < 576) }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    setMobileView(window.innerWidth < 576)
  }, [])

  const aboutContent = (
    <div className="contact-content about-content col-sm-7 col-sm-pull-4 col-xs-12">
      <h3>Contact</h3>
      <address className="contact">
        <h4>Personal Email</h4>
        <a href="mailto:leahjia1@gmail.com" className="text-underline">leahjia1@gmail.com</a>
      </address>
    </div>
  )

  return (
    <main>
      <div className="container">
        <div className="row">
          <ProfileCard />
          {aboutContent}
          <MessageForm mobileView={mobileView} />
        </div>
      </div>
    </main>
  )
}
