import React, { useState } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { getDatabase, ref as dbRef, set as firebaseSet } from 'firebase/database';
import axios from 'axios';

export default function MessageForm(props) {
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const message = e.target[1].value;
    const timestamp = Date.now();
    const obj = {
      email: email,
      message: message,
      timestamp: timestamp
    };
    const msgRef = dbRef(getDatabase(), `messages/${timestamp}`)
    firebaseSet(msgRef, obj);
    sendMsg(obj);
    setIsSent(true);

    e.target[0].value = '';
    e.target[1].value = '';
  };

  setTimeout(() => { setIsSent(false); }, 5000);

  const sendMsg = async (obj) => {
    try {
      // const URL = process.env.REACT_APP_SERVER_URL + '/api/data';
      const URL = process.env.REACT_APP_AWS_API_URL;

      // For HTTP POST with fetch: change the URL and replace axios
      // const response = await fetch(URL, {
      //   method: 'POST',
      //   mode: 'no-cors',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(obj),
      // });
      // const data = await response.json();
      // console.log('API Response:', (data));
      // return data;

      axios.post(URL, obj)
        .then(() => {
          console.log('I got your message, thanks :)');
        })
        .catch((error) => { console.error('Got error: ', error); });
    } catch (error) { console.error('Init Fetch Error:', error); }
  };

  return (
    <>
      <Form className="form" onSubmit={handleSubmit}>
        <h3 className='leave-message-title'>📬 Leave a message:</h3>
        <div className='form-group'>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="form-label">📥 Email address:</Form.Label>
            {props.mobileView ? (
              < Form.Control type="email" className="form-control" pattern=".+@+.+." required />
            ) : (
              <FloatingLabel controlId="floatingInput" label="Email address" className='floating-label'>
                < Form.Control type="email" placeholder="name@example.com" className="form-control" pattern=".+@+.+." required />
              </FloatingLabel>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="form-label">📮 Message:</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder='Typing...' className="form-control" required />
          </Form.Group>

        </div>
        {isSent ? (
            <h4 className="success-message">Sent!</h4>
        ) : (
            <button type="submit" className={"btn form-button" + (isSent ? ' sent' : '')}>
            {isSent ? 'Sent!' : 'Submit'}
            </button>
        )}
      </Form>
    </>
  );
}
