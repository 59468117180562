import React, { useEffect, useState } from 'react';

function linkItem(url, icon) {
  return (
    <li><a href={url} target="_blank" rel="noopener noreferrer"><span className={`fa ${icon}`}></span></a></li>
  )
}

export default function Footer() {
  const [currentMo, setCurrentMo] = useState('');
  const [currentYr, setCurrentYr] = useState('');

  useEffect(() => {
    const today = new Date();
    setCurrentMo(`${today.toLocaleString('default', { month: 'short' })}`);
    setCurrentYr(`${today.getFullYear()}`);
  }, []);

  return (
    <footer>
      <div>
        <h3>Leah Jia</h3>
        <ul className="links" >
          {linkItem('mailto:leahjia1@gmail.com', 'fa-envelope')}
          {linkItem('https://www.linkedin.com/in/leahjia/', 'fa-linkedin')}
          {linkItem('https://github.com/leahjia', 'fa-github')}
          {linkItem('https://www.instagram.com/leahjia_/', 'fa-instagram')}
          {linkItem('https://twitter.com/leahjia_', 'fa-twitter')}
        </ul>
      </div>
      <div className="copyright">
        <p>&copy;{currentYr} Created by <a className="myName" href="https://www.linkedin.com/in/leahjia/" target="_blank" rel="noopener noreferrer">Leah 🎾</a> | Seattle, WA | Last Update: {currentMo} {currentYr}</p>
      </div>
    </footer>
  )
}
