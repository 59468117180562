import React from "react";

export default function ProfileCard() {
  const blurDivs = document.querySelectorAll(".blur-img");
  blurDivs.forEach((div) => {
    const img = div.querySelector("img");
    function loaded() {
      div.classList.add("loaded");
    }
    // call func either right away or soon as it's loaded
    if (img.complete) {
      loaded();
    } else {
      img.addEventListener("load", loaded);
    }
  });

  return (
    <div className="contact-info col-sm-4 col-md-3 col-md-offset-1 col-sm-push-8 col-xs-12">
      <div className="blur-img" style={{ backgroundImage: 'url("/img/portrait-sm.png")' }}>
        <img className="portrait" loading="lazy" src="https://leahjia.s3.amazonaws.com/portrait" alt="Leah Jia"></img>
      </div>
      <h2>Leah Jia</h2>
      <p className="text-under-portrait">
        Software Engineer 👩‍💻
        <br></br>
        <a href="https://www.washington.edu/" target="_blank" rel="noopener noreferrer" className="text-underline">
          University of Washington
        </a>
        <br></br>
        AWS Cloud Practitioner 🌥️
        <br></br>
      </p>
    </div>
  );
}
